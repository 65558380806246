import { productZodSchema } from '@orus.eu/product'
import { z } from 'zod'

export type OrganizationType = (typeof allOrganizationType)[number]
export const allOrganizationType = ['orus', 'broker', 'partner'] as const
export const organizationTypeSchema = z.enum(allOrganizationType)

export const organizationDefinitionSchema = z.object({
  deleted: z.boolean().optional(),
  displayName: z.string(),
  address: z.string(),
  email: z.string(),
  orias: z.string(),
  legalEntity: z.string(),
  phone: z.string().optional(),
  selectableProducts: z.array(productZodSchema),
  hasEmbeddedFunnel: z.boolean().optional(),
  canTerminateContracts: z.boolean().optional(),
  emailDomain: z.string().optional(),
  type: organizationTypeSchema.optional(),
  commissionRate: z.record(productZodSchema, z.number().optional()).optional(),

  defaultPartnerManagementFeeRate: z
    .number()
    .optional()
    .refine((value) => !value || (value >= 0 && value <= 0.2)),

  defaultPartnerApplicationFee: z
    .number()
    .optional()
    .refine((value) => !value || value >= 0),

  /**
   * Whether the organization is managed by Orus (= Orus sales can call the leads, handle renewal, etc.)
   * Used by ops team
   */
  isManagedByOrus: z.boolean(),

  /**
   * Used to display or not the partner fees section in the quote editor to the members of this organization
   */
  canEditPartnerFees: z.boolean(),

  /** Hashes are computed with `createHash('sha256').update(token).digest('hex')` */
  apiTokenHashes: z.array(z.string()),
})

/**
 * Details about the fields of the organization definition
 * Used to display details in the UI of the JSON editor
 */
export const organizationDefinitionFieldsDetails: Record<
  keyof OrganizationDefinition,
  {
    description: string
    type: string
    optional: boolean
    example: string
  }
> = {
  deleted: {
    description: "Indique si l'organisation est supprimée",
    type: 'boolean',
    optional: true,
    example: 'false',
  },
  displayName: {
    description: "Valeur affichée dans l'interface utilisateur",
    type: 'string',
    optional: false,
    example: 'Legalstart',
  },
  address: {
    description: 'Adresse',
    type: 'string',
    optional: false,
    example: '50 rue d’Hauteville 75010 Paris',
  },
  email: {
    description: 'Email',
    type: 'string',
    optional: false,
    example: 'contact@legalstart.fr',
  },
  orias: {
    description: 'Orias',
    type: 'string',
    optional: false,
    example: '16005304',
  },
  legalEntity: {
    description: 'Raison sociale',
    type: 'string',
    optional: false,
    example: 'YOLAW SAS',
  },
  phone: {
    description: 'Téléphone',
    type: 'string',
    optional: true,
    example: '0123456789',
  },
  selectableProducts: {
    description: "Produits sélectionnables par l'organisation",
    type: '("mrph" | "mrpw" | "muta" | "rcph" | "rcda" | "rcpa")[]',
    optional: false,
    example: '["mrph", "rcph", "rcda"]',
  },
  type: {
    description: "Type de l'organisation",
    type: '("orus" | "broker" | "partner")',
    optional: true,
    example: '"partner"',
  },
  hasEmbeddedFunnel: {
    description: "Indique si l'organisation a un funnel dédié",
    type: 'boolean',
    optional: true,
    example: 'true',
  },
  canTerminateContracts: {
    description: "Indique si l'organisation a le droit ou non de résilier l'ancien contrat",
    type: 'boolean',
    optional: true,
    example: 'true',
  },
  emailDomain: {
    description: "Domaine email de l'organisation (après @)",
    type: 'string',
    optional: true,
    example: 'legalstart.fr',
  },
  commissionRate: {
    description: 'Taux de commission par produit',
    type: 'record(product, number)',
    optional: true,
    example: '{"mrph": 0.1, "mrpw": 0.1, "muta": 0.1, "rcph": 0.1, "rcda": 0.1, "rcpa": 0.1}',
  },
  defaultPartnerManagementFeeRate: {
    description: 'Frais de gestion par défaut (en pourcentage, entre 0 pour 0% et 0.2 pour 20%)',
    type: '0 <= number <= 0.2',
    optional: true,
    example: '0.1',
  },
  defaultPartnerApplicationFee: {
    description: 'Frais de dossier par défaut (en €, supérieur à 0)',
    type: 'number >= 0',
    optional: true,
    example: '100',
  },
  isManagedByOrus: {
    description:
      "Orus est responsable du closing de ces prospects. Le travail de l'organisation s'arête à partir du moment ou les leads ont été fournis à orus. Les prospects reçoivent les sequences Hubspot et sont appelés par les sales.",
    type: 'boolean',
    optional: false,
    example: 'true',
  },
  canEditPartnerFees: {
    description:
      'Si true, la section "Frais partenaires" sera affichée dans l\'éditeur de devis pour les membres de cette organisation',
    type: 'boolean',
    optional: false,
    example: 'true',
  },
  apiTokenHashes: {
    description: 'Hashes des tokens API',
    type: 'string[]',
    optional: false,
    example: '["f4aa0ba0a12f0918c81016f9f5893022ce9b28ff463dc29b2919a6c2a3a38c5b"]',
  },
}

export const organizationsDefinitionSchema = z.record(z.string(), organizationDefinitionSchema)

export const orusOrganization = 'orus'

export const orusTestOrganization = 'orus-test'

export type OrganizationDefinition = z.infer<typeof organizationDefinitionSchema>

export type Organization = OrganizationDefinition & { technicalName: string }

export type OrganizationFunnelInfo = Pick<
  Organization,
  'technicalName' | 'email' | 'phone' | 'hasEmbeddedFunnel' | 'isManagedByOrus'
>

export type OrganizationsDefinition = z.infer<typeof organizationsDefinitionSchema>

export type OrganizationPricingInfo = Pick<Organization, 'type' | 'commissionRate'>

export const organizationMock: OrganizationDefinition = {
  type: 'orus',
  commissionRate: {
    rcph: 0.1,
  },
  selectableProducts: ['mrph', 'mrpw', 'rcph', 'muta', 'rcda', 'es-rcph', 'rcpa'],
} as OrganizationDefinition
